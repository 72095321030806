<template>
  <div></div>
</template>

<script>
import {ref, onBeforeMount} from "vue";
import {useRoute} from "vue-router";
// import axios from "axios";
// import {authWithBadgr} from "@/Helpers/ApiCalls/BadgrAPICalls";
import {mapActions, useStore} from "vuex";
import router from "@/router";

export default {
  name: "BadgrRedirectLanding",
  setup() {
    const {code, scope} = useRoute().query;
    const store = useStore();

    onBeforeMount(() => {
      if (!code) {
        router.back();
      }
      getBadgrAuthValue();
    })

    async function getBadgrAuthValue() {
      let content = {
        code: code
      };
      await store.dispatch("badgr/getAuth", content);
    }
  }
}
</script>

<style scoped>

</style>